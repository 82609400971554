(function ($) {

  $(function () {

    $('#deleteRoleConfirmationDialog').on('show.bs.modal', function (event) {
      // Button that triggered the modal
      var button = $(event.relatedTarget)

      // Extract info from data-* attributes
      var clientId = button.data('client')
      var roleCode = button.data('role')

      $('input[name=clientId]').val(clientId);
      $('input[name=roleCode]').val(roleCode);
    });

  });

})(jQuery);